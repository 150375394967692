input[type="number"]::-webkit-inner-spin-button, input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.quantity input {
  float: left;
  width: 50px;
  height: 33px;
  border-radius: var(--border-radius);
  border: 1px solid #eee;
  margin: 0;
  padding: 0 15px 0 10px;
  display: block;
}

.quantity input:focus {
  outline: 0;
}

.quantity-header {
  float: left;
  height: 33px;
  position: relative;
}

.quantity-button {
  cursor: pointer;
  width: 20px;
  -webkit-user-select: none;
  user-select: none;
  border-left: 1px solid #eee;
  font-size: 13px;
  transform: translateX(-100%);
}

.quantity-button.quantity-up {
  height: 50%;
  border-bottom: 1px solid #eee;
  position: absolute;
  top: 0;
}

.quantity-button.quantity-down {
  height: 50%;
  position: absolute;
  bottom: -1px;
}

/*# sourceMappingURL=index.25a02e2b.css.map */
