input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.quantity input {
  display: block;
  float: left;

  width: 50px;
  height: 33px;

  padding: 0;
  margin: 0;

  padding-left: 10px;
  padding-right: 15px;

  border-radius: var(--border-radius);
  border: 1px solid #eee;
}

.quantity input:focus {
  outline: 0;
}

.quantity-header {
  float: left;
  position: relative;
  height: 33px;
}

.quantity-button {
  cursor: pointer;
  border-left: 1px solid #eee;
  width: 20px;
  font-size: 13px;
  transform: translateX(-100%);
  user-select: none;
}

.quantity-button.quantity-up {
  position: absolute;
  height: 50%;
  top: 0;
  border-bottom: 1px solid #eee;
}

.quantity-button.quantity-down {
  position: absolute;
  bottom: -1px;
  height: 50%;
}
